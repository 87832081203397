<div *ngIf="isShow" class="content">
  <div class="col-12 text-end custom-btn-close">
    <img (click)="closeDialog()" src="assets/images/icon/svg/close.svg" alt="" />
  </div>

  <div class="d-flex">
    <div class="width-250 d-none d-md-block desktop-gallery">
      <ng-container *ngIf="!loadingData; else showGallery">
        <p-galleria
          class="custom-thumbnail-pc"
          [(value)]="images"
          (activeIndexChange)="activeIndexChange('custom-thumbnail-pc')"
          [autoPlay]="false"
          [numVisible]="3"
        >
          <ng-template pTemplate="item" let-item>
            <img [src]="getImageFromUrl(item.itemSrc, widthType.W250)" alt="" />
          </ng-template>
          <ng-template pTemplate="thumbnail" let-item>
            <div class="row row-nogutter justify-content-center m-0">
              <img [src]="getImageFromUrl(item.thumbnailSrc, widthType.W80)" alt="" />
            </div>
          </ng-template>
        </p-galleria>
      </ng-container>
      <ng-template #showGallery>
        <div class="loading-container">
          <div class="img-loading">
            <div class="images-loadding-center">
              <img src="/assets/images/icon/svg/loadding.svg" alt="" />
              <br />
              <label for="">Loading...</label>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="isview" class="flex-fill gift">
      <div class="p-0">
        <span class="f-s-18 f-w-500 color-111 m-0">{{ itemDetail?.title }}</span>
        <div class="row">
          <div class="col-6 color-111 f-s-12 f-w-400 m-t-4">
            <span>{{ link }}</span>
          </div>
          <div class="m-b-23 m-t-16">
            <p-progressBar [value]="itemDetail?.totalDonatePercent"></p-progressBar>
            <div class="row m-t-4">
              <div class="col-6 color-718096 f-s-12 f-w-500">
                <label for=""
                  >{{ itemDetail?.totalDonatePercent }}% (¥{{
                    itemDetail?.totalDonate | tienTe
                  }})</label
                >
              </div>
              <div class="col-6 text-end color-111 f-s-18 f-w-500">
                <label for="">¥{{ itemDetail?.displayMoney | tienTe }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Mobile Screen -->
      <div class="d-block d-md-none col-12 m-b-8 mobile-gallery m-b-24">
        <ng-container *ngIf="!loadingData; else showMobileGallery">
          <p-galleria
            [(value)]="images"
            [containerStyle]="{ 'max-width': '310px' }"
            [numVisible]="3"
            [showThumbnailNavigators]="false"
          >
            <ng-template pTemplate="item" let-item>
              <img
                [src]="getImageFromUrl(item.itemSrc, widthType.W250)"
                alt=""
                style="width: 100%"
              />
            </ng-template>
            <ng-template pTemplate="thumbnail" let-item>
              <div class="thumbnail-img-custom">
                <img [src]="getImageFromUrl(item.thumbnailSrc, widthType.W80)" alt="" />
              </div>
            </ng-template>
          </p-galleria>
        </ng-container>

        <ng-template #showMobileGallery>
          <div class="loading-container-mobile">
            <div class="img-loading-mobile">
              <div class="images-loadding-center-mobile">
                <img src="/assets/images/icon/svg/loadding.svg" alt="" />
                <br />
                <label for="">Loading...</label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <!-- END: Mobile Screen -->

      <div class="m-b-24" *ngIf="itemDetail?.message">
        <div class="creator-message m-b-8">
          <span class="creator-message-name m-r-8">
            <img
              [src]="getImageFromUrl(creatorInfo?.profileImage, widthType.W100)"
              (error)="handleAvatarError($event)"
              alt=""
            />
            <span class="color-111 f-w-500 f-s-12">{{ creatorInfo?.displayName }}</span>
          </span>
          <span class="color-111 f-w-500 f-s-12">
            {{ '::Wishlist:FanView:Detail:MessageFrom' | abpLocalization }}
          </span>
        </div>
        <p class="creator-message-label m-b-0">
          {{ itemDetail?.message }}
        </p>
      </div>

      <div class="row m-t-24" *ngIf="itemDetail?.fansDonate.length > 0">
        <div class="col-6 color-111 f-s-10 f-w-500">
          <span>{{ '::Wishlist:Creator:Detail:Supporter' | abpLocalization }}</span>
        </div>
        <div class="col-6 text-end color-111 f-s-10 f-w-500">
          <span
            >{{ itemDetail?.totalUserDonate
            }}{{ '::Wishlist:Creator:Detail:People' | abpLocalization }}</span
          >
        </div>
        <div class="m-t-4">
          <hr class="m-0" />
        </div>
      </div>

      <div id="list-fan-donate" class="heigth-fan-donate">
        <div *ngFor="let item of itemDetail?.fansDonate" class="row mt-12 fs-12 f-w-500 m-t-10">
          <div class="col-9">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img
                  class="avatar-fan-donate"
                  (error)="handleAvatarError($event)"
                  [src]="getUrlAvatar(item.profileImage)"
                  alt=""
                />
              </div>
              <div [pTooltip]="item.displayName" class="color-111 m-l-8 truncate">
                {{ item.displayName }}
              </div>
              <div [pTooltip]="item.userName" class="color-999 m-l-8 truncate">
                {{ item?.userName ? '@' + item.userName : item.userName }}
              </div>
            </div>
            <div *ngIf="item.message" class="color-111 m-t-5 f-w-400">
              <span class="message"> {{ item.message }}</span>
            </div>
          </div>
          <div class="col-3 d-flex justify-content-end">
            <span class="color-111"> ¥{{ item.fund | tienTe }}</span>
          </div>
          <div class="m-t-12">
            <hr class="m-0" />
          </div>
        </div>
      </div>
      <div *ngIf="isCreatorView" class="m-t-24">
        <p-button
          *ngIf="this.itemDetail?.status === giftStatus.Created"
          styleClass="p-button-rounded w-100 button-content m-b-8"
          (click)="viewDetail(true)"
        >
          <span class="me-2 f-s-13 f-w-500">
            {{ '::Wishlist:Creator:BtnEdit' | abpLocalization }}
          </span>
        </p-button>

        <p-button
          *ngIf="this.itemDetail?.status !== giftStatus.Created"
          (click)="viewDetail(false)"
          styleClass="p-button-rounded w-100 button-content m-b-8"
        >
          <span class="me-2 f-s-13 f-w-500">
            {{ '::Wishlist:Creator:BtnViewDetail' | abpLocalization }}
          </span>
        </p-button>

        <p-button
          *ngIf="
            this.itemDetail?.status === giftStatus.Created ||
            this.itemDetail?.status === giftStatus.Queued
          "
          (click)="deleteGift(this.id)"
          styleClass="custom-btn-delete p-button-outlined w-100 m-b-8"
        >
          <span class="me-2 f-s-13 f-w-500">
            {{ '::Wishlist:CheckOut:Delete' | abpLocalization }}
          </span>
        </p-button>
      </div>
    </div>

    <div *ngIf="!isview" class="flex-fill gift">
      <div class="p-0">
        <span class="f-s-18 f-w-500 color-111 m-0">{{ itemDetail?.title }}</span>
        <div class="f-s-12 f-w-400 color-111 m-t-12">
          <span>{{ link }}</span>
        </div>
        <form [formGroup]="formData" autocomplete="off">
          <div class="m-b-23 m-t-16">
            <p-progressBar [value]="itemDetail?.totalDonatePercent"></p-progressBar>
            <div class="row m-t-4">
              <div class="col-6 color-718096 f-s-12 f-w-500">
                <label for=""
                  >{{ itemDetail?.totalDonatePercent }}% (¥{{
                    itemDetail?.totalDonate | tienTe
                  }})</label
                >
              </div>
              <div class="col-6 text-end color-111 f-s-18 f-w-500">
                <label for="">¥{{ itemDetail?.displayMoney | tienTe }}</label>
              </div>
            </div>
          </div>

          <!-- BEGIN: Mobile Screen -->
          <div class="d-block d-md-none col-12 m-b-8 mobile-gallery m-b-24">
            <ng-container *ngIf="!loadingData; else showMobileGallery">
              <p-galleria
                [(value)]="images"
                [containerStyle]="{ 'max-width': '310px' }"
                [numVisible]="3"
                [showThumbnailNavigators]="false"
              >
                <ng-template pTemplate="item" let-item>
                  <img
                    [src]="getImageFromUrl(item.itemSrc, widthType.W250)"
                    alt=""
                    style="width: 100%"
                  />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                  <div class="thumbnail-img-custom">
                    <img [src]="getImageFromUrl(item.thumbnailSrc, widthType.W80)" alt="" />
                  </div>
                </ng-template>
              </p-galleria>
            </ng-container>

            <ng-template #showMobileGallery>
              <div class="loading-container-mobile">
                <div class="img-loading-mobile">
                  <div class="images-loadding-center-mobile">
                    <img src="/assets/images/icon/svg/loadding.svg" alt="" />
                    <br />
                    <label for="">Loading...</label>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <!-- END: Mobile Screen -->

          <div class="m-b-24" *ngIf="itemDetail?.message">
            <div class="creator-message m-b-8">
              <span class="creator-message-name m-r-8">
                <img
                  [src]="getImageFromUrl(creatorInfo?.profileImage, widthType.W100)"
                  (error)="handleAvatarError($event)"
                  alt=""
                />
                <span class="color-111 f-w-500 f-s-12">{{ creatorInfo?.displayName }}</span>
              </span>
              <span class="color-111 f-w-500 f-s-12">
                {{ '::Wishlist:FanView:Detail:MessageFrom' | abpLocalization }}
              </span>
            </div>
            <p class="creator-message-label m-b-0">
              {{ itemDetail?.message }}
            </p>
          </div>
          <div class="row m-t-24" *ngIf="itemDetail?.fansDonate.length > 0">
            <div class="col-6 color-111 f-s-10 f-w-500">
              <span>{{ '::Wishlist:Creator:Detail:Supporter' | abpLocalization }}</span>
            </div>
            <div class="col-6 text-end color-111 f-s-10 f-w-500">
              <span
                >{{ itemDetail?.totalUserDonate
                }}{{ '::Wishlist:Creator:Detail:People' | abpLocalization }}</span
              >
            </div>
            <div class="m-t-4">
              <hr class="m-0" />
            </div>
          </div>

          <div
            id="list-fan-donate"
            class="heigth-fan-donate m-b-20"
            style="height: 140px !important"
            *ngIf="itemDetail?.fansDonate.length > 0"
          >
            <div *ngFor="let item of itemDetail?.fansDonate" class="row mt-12 fs-12 f-w-500 m-t-10">
              <div class="col-9">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      class="avatar-fan-donate"
                      (error)="handleAvatarError($event)"
                      [src]="getUrlAvatar(item.profileImage)"
                      alt=""
                    />
                  </div>
                  <div [pTooltip]="item.displayName" class="color-111 m-l-8 truncate">
                    {{ item.displayName }}
                  </div>
                  <div [pTooltip]="item.userName" class="color-999 m-l-8 truncate">
                    {{ item?.userName ? '@' + item.userName : item.userName }}
                  </div>
                </div>
                <div *ngIf="item.message" class="color-111 m-t-5 f-w-400">
                  <span class="message"> {{ item.message }}</span>
                </div>
              </div>
              <div class="col-3 d-flex justify-content-end">
                <span class="color-111"> ¥{{ item.fund | tienTe }}</span>
              </div>
              <div class="m-t-12">
                <hr class="m-0" />
              </div>
            </div>
          </div>
          <div class="col m-b-8">
            <input
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              currencyMask
              formControlName="Amount"
              class="p-inputtext"
              placeholder="{{ '::Wishlist:Detail:EnterAmount' | abpLocalization }}"
              (focusout)="amountChange($event)"
            />
            <dft-validation [control]="formData.get('Amount')"></dft-validation>
          </div>
          <div class="button-container m-b-24">
            <p-button
              [disabled]="getAmount() < 500"
              (click)="getAmount() >= 500 && setAmount(500)"
              [label]="'¥' + ('500' | tienTe)"
              styleClass="p-button-outlined p-button-secondary"
            ></p-button>
            <p-button
              [disabled]="getAmount() < 1000"
              (click)="getAmount() >= 1000 && setAmount(1000)"
              [label]="'¥' + ('1000' | tienTe)"
              styleClass="p-button-outlined p-button-secondary"
            ></p-button>
            <p-button
              [disabled]="getAmount() < 2500"
              (click)="getAmount() >= 2500 && setAmount(2500)"
              [label]="'¥' + ('2500' | tienTe)"
              styleClass="p-button-outlined p-button-secondary"
            ></p-button>
            <p-button
              (click)="setAmountAll()"
              label="{{ '::Wishlist:Detail:FullAmount' | abpLocalization }}"
              styleClass="p-button-outlined p-button-secondary"
            ></p-button>
          </div>
          <div class="m-b-4 f-s-10 f-w-500 color-111">
            <span>{{ '::Wishlist:Detail:GiftMessage' | abpLocalization }}</span>
          </div>
          <textarea
            placeholder="{{ '::Wishlist:Detail:EnterYourMessage' | abpLocalization }}"
            maxlength="200"
            class="w-100 custom-textarea"
            rows="3"
            cols="12"
            pInputTextarea
            formControlName="Message"
          ></textarea>
          <div class="text-center m-t-24">
            <p-button
              class="btn-gipt-now"
              styleClass="p-button-rounded w-100"
              (click)="!isSubmit && checkPriceBeforeAddGift()"
              [loading]="isSubmit"
              [disabled]="isSubmit || isDisable"
            >
              <span class="me-2">{{ '::Wishlist:ButtonGiptNow' | abpLocalization }}</span>
            </p-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
